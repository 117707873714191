import { useCall2Data } from '@Utils/useReadCall';
import { useOneCTWallet } from '@Views/OneCT/useOneCTWallet';
import { useEffect, useState } from 'react';
import { erc20ABI } from 'wagmi';

const Test: React.FC<any> = ({}) => {
  // const d = useOneCTWallet();
  return (
    <div>
      <button onClick={() => Dedupe()}>Err</button>
    </div>
  );
};

export { Test };
